import { EventEmitter, Injectable, Output } from '@angular/core';
import { ConnectServerService } from './connect-server.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Observable, Subject, lastValueFrom } from 'rxjs';
import { Connect } from '../classes/connect';
import { PopupDialogService } from './popup-dialog.service';
import { ObjPopupResponseCallApi } from '../classes/obj-popup-response-call-api';
import { UserLogged } from '../classes/user-logged';
import { Store } from '@ngrx/store';
import * as UserActions from '../storage-ngrx/user/user.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  set userLogged(value: UserLogged) {
    this._userLogged = value;
  }
  get userLogged(): UserLogged {
    return this._userLogged;
  }
  private _userLogged!: UserLogged;

  // @Output() userLoginIn = new EventEmitter();

  private datiUserLogged = new Subject<UserLogged>;


  constructor(private connectServerService: ConnectServerService, private cookieService: CookieService,
    private router: Router, private popupdialogService: PopupDialogService,
    private store: Store) { }

  private async getCsrf(): Promise<any> {
    // const request$ = this.http.get(`/api/sanctum/csrf-cookie`).pipe(take(1));
    const request$ = this.connectServerService.getRequestNew(Connect.urlServerLara, 'sanctum/csrf-cookie', {});
    return await lastValueFrom<any>(request$);
  }

  loginServer(email_send: string, password_send: string, rememberme: boolean) {
    this.getCsrf().then(
      (response) => {
        // console.log('prima risposta:', response);
        // this.http.post(`${this.urlServerWall}/register`, {
        this.connectServerService.postRequestNew(Connect.urlServerLara, 'login', {
          email: email_send,
          password: password_send,
        }).subscribe((esito: any) => {
          // console.log('login!', esito);
          localStorage.setItem('authOk', 'YES');
          this.router.navigate(['home']);
        });
      }
    );
  }

  logoutServer() {
    this.connectServerService.postRequestNew(Connect.urlServerLara, 'logout',
      {}).subscribe((esito: any) => {
        // console.log('logout!', esito);
        localStorage.removeItem('authOk');
        // this.cookieService.delete('/');
        this.cookieService.delete('XSRF-TOKEN');
        this.cookieService.deleteAll('/');
        this.router.navigate(['/login']);
        // Dispatcia l'azione di logout per reimpostare lo stato dello store
        this.store.dispatch(UserActions.logout());

      });
  }

  isAuthenticated(): Observable<boolean> {
    // Esegui una richiesta HTTP per verificare lo stato di autenticazione
    //return this.connectServerService.getRequestNew(Connect.urlServerLara, 'api/user', {});
    return this.connectServerService.getRequestNew(Connect.urlServerLara, 'user', {});
  }

  /**
   * Usata per le info (si chiama sicuro nel main-home)
   * @returns
   */
  userInfo(): Observable<UserLogged> {
    return this.connectServerService.getRequestNew(Connect.urlServerLara, 'user', {});
  }



  saveInfoUserLogged(user: UserLogged) {
    this.datiUserLogged.next(user);
  }

  getInfoUserLogged(): Observable<UserLogged> {
    return this.datiUserLogged.asObservable();
  }

  forgotPasswordServer(email_send: string) {
    this.connectServerService.postRequestNew(Connect.urlServerLara, `forgot-password`,
      {
        email: email_send
      }).subscribe((esito: any) => {
        if (esito.message) {
          const obj_esito = new ObjPopupResponseCallApi();
          obj_esito.titolo = 'Successo';
          obj_esito.messaggio = esito.message;
          this.popupdialogService.popUpDialogSuccess(obj_esito);
        }
        // console.log('forgot password!', esito);
      });
  }

  resetPasswordServer(token_val: string, email_send: string, password_send: string, password_sendconfirm: string) {
    this.connectServerService.postRequestNew(Connect.urlServerLara, `reset-password`, {
      email: email_send,
      password: password_send,
      password_confirmation: password_sendconfirm,
      token: token_val
    }).subscribe((esito: any) => {
      if (esito.message) {
        const obj_esito = new ObjPopupResponseCallApi();
        obj_esito.titolo = 'Successo';
        obj_esito.messaggio = esito.message;
        this.popupdialogService.popUpDialogSuccess(obj_esito);
        this.router.navigate(['login']);
      }
      // console.log('reset password!', esito);
    });
  }

  registerNewUser(name_reg: string, email_reg: string, password_reg: string, passwordconfirm_reg: string,
    nome_user: string, cognome_user: string, codicefiscale: string) {
    return this.connectServerService.postRequestNew(Connect.urlServerLara, `register`, {
      name: name_reg,
      email: email_reg,
      password: password_reg,
      password_confirmation: passwordconfirm_reg,
      nome: nome_user,
      cognome: cognome_user,
      codicefiscale: codicefiscale,
    });
  }

  get isLoggedIn(): boolean {
    /*const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;*/
    return !!localStorage.getItem('authOk');

  }
}

import { Component, Inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupResponseCallApi } from '../../interfaces/popup-response-call-api';
import { PopupInfoVal } from '../../interfaces/popup-info-val';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';


@Component({
  selector: 'app-modal-newup',
  standalone: true,
  imports: [ReactiveFormsModule, MatDialogModule, MatButtonModule, MatRadioModule],
  templateUrl: './modal-newup.component.html',
  styleUrl: './modal-newup.component.scss'
})
export class ModalNewupComponent {
  classButton = '';
  titolo = '';
  testo_info = '';
  testo_scelta_1 = '';
  valore_1 = null;
  testo_scelta_2 = '';
  valore_2 = null;
  popupForm: UntypedFormGroup;
  floatLabelControl = new UntypedFormControl();
  disabled_button = true;
  scelta = null;

  constructor(@Inject(MAT_DIALOG_DATA) public valori_passati: PopupResponseCallApi,
    public dialogRef: MatDialogRef<ModalNewupComponent>, private fb: UntypedFormBuilder) {
    // console.log(valori_passati);
    if (valori_passati.tipo === 'error') {
      this.classButton = 'btn btn-danger';
      this.dialogRef.disableClose = true;
    } else if (valori_passati.tipo === 'success') {
      this.classButton = 'btn btn-primary';
    } else {
      this.classButton = 'btn btn-warning';
    }
    this.titolo = valori_passati.titolo;
    this.testo_info = valori_passati.messaggio;
    const obj_popupinfoval: PopupInfoVal | undefined = valori_passati.valore;
    if (obj_popupinfoval && obj_popupinfoval.myobject) {
      const obj = obj_popupinfoval.myobject;
      this.testo_scelta_1 = obj.testo1;
      this.testo_scelta_2 = obj.testo2;
      this.valore_1 = obj.valore1;
      this.valore_2 = obj.valore2;
    }
    this.popupForm = fb.group({
      floatLabel: this.floatLabelControl
    });

  }

  ngOnInit(): void {
    this.floatLabelControl.valueChanges.subscribe(
      (valSelect) => {
        this.disabled_button = false;
        this.scelta = valSelect;
        // console.log(valSelect);
      }
    )
  }

  vai(): void {
    this.dialogRef.close({ avanti: this.valori_passati.dialogResultGo, button_who: 'go', valori: this.valori_passati, scelta: this.scelta });
  }

  chiudi(): void {
    this.disabled_button = true;
    this.floatLabelControl.setValue('');
    this.dialogRef.close({ avanti: this.valori_passati.dialogResultChiudi, button_who: 'close', valori: this.valori_passati });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, timeout } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectServerService {

  // ATTENZIONE: timeout non funziona. Viene sovrascritto nell' HttpErrorInterceptor

    // public sub = new BehaviorSubject<number>(0);
    // è il riferimento al popup successo. Usato per esempio per chiuderlo: dialogRefPopSucc.close('dettaglioFornitore');
    dialogRefPopSucc = null;

    /*httpOptions = {
        headers: new HttpHeaders({
            // 'Content-Type':  'application/json',
        })
    };*/
    constructor(private http: HttpClient, private router: Router) {
    }

    /*setData(data){
        this.sub.next(data);
      }*/
    getRequest(urlServer: string, urlFunction: string, parametri: string): Observable<any> {
        return this.http.get(urlServer + urlFunction + parametri)
            .pipe(
                timeout(30000),
                // catchError(this.handleError)
            );
    }

    postRequestNew<T>(urlServer: string, urlFunction: string, parametri: object): Observable<any> {
      return this.http.post(urlServer + urlFunction, parametri);
    }
    getRequestNew<T>(urlServer: string, urlFunction: string, parametri: any): Observable<any> {
      return this.http.get(urlServer + urlFunction, {
        params: parametri,
        // observe: 'response'
        // observe: 'events'
      });
    }


    getRequestLara<T>(urlServer: string, urlFunction: string, parametri: any): Observable<any> {
        return this.http.get(urlServer + urlFunction,
            {
                params: parametri,
                // observe: 'response'
                // observe: 'events'
            }
            // {params: parametri, observe: 'response'}
        )
            .pipe(
                timeout(30000)
            );
    }

    getRequestFileLara<T>(urlServer: string, urlFunction: string, parametri: any): Observable<any> {
        /*const headers = new HttpHeaders();
        headers.set('responseType', 'arraybuffer');*/
        return this.http.get(urlServer + urlFunction, {
            responseType: 'blob',
            params: parametri
        })
            .pipe(
                timeout(60000)
            );
    }
    postRequestFileLara<T>(urlServer: string, urlFunction: string, parametri: any): Observable<any> {
        /*const headers = new HttpHeaders();
        headers.set('responseType', 'arraybuffer');*/
        return this.http.post(urlServer + urlFunction,
            {
                params: parametri
            },
            {
                responseType: 'blob',
                //observe: 'response',
            }
        )
            .pipe(
                timeout(30000),
                map((item) => {
                    let blob = new Blob([]);
                    if (item) {
                        blob = new Blob([item], {
                            type: 'application/pdf'
                        });
                    }
                    return blob;
                })
            );
    }
    postRequestFileLaraGeneric<T>(urlServer: string, urlFunction: string, parametri: any): Observable<any> {
      /*const headers = new HttpHeaders();
      headers.set('responseType', 'arraybuffer');*/
      return this.http.post(urlServer + urlFunction,
          {
              params: parametri
          },
          {
              responseType: 'blob',
              observe: 'response',
          }
      )
          .pipe(
              timeout(30000)
          );
  }

    postRequestFileLaraWord<T>(urlServer: string, urlFunction: string, parametri: any): Observable<any> {
      /*const headers = new HttpHeaders();
      headers.set('responseType', 'arraybuffer');*/
      return this.http.post(urlServer + urlFunction,
          {
              params: parametri
          },
          {
              responseType: 'blob',
              //observe: 'response',
          }
      )
          .pipe(
              timeout(30000),
              map((item) => {
                  let blob = new Blob([]);
                  if (item) {
                      blob = new Blob([item], {
                          type: 'application/docx'
                      });
                  }
                  return blob;
              })
          );
  }

    /*
    requestLaraGetAsync(urlServer: string, urlFunction: string, parametri: HttpParams | {
        [param: string]: string | number | boolean | readonly (string | number | boolean)[];
    }): Promise<any>{
        return this.http.get(urlServer + urlFunction,
            {
                params: parametri,
                // observe: 'response'
                // observe: 'events'
            }).pipe(
            // timeout(4000),
            // catchError(this.handleError)
        ).toPromise();
    }
*/
    async getRequestLaraAsync<T>(urlServer: string, urlFunction: string, parametri: any): Promise<any> {
        const chiamata$ = this.http.get(urlServer + urlFunction,
            {
                params: parametri,
                // observe: 'response'
                // observe: 'events'
            }).pipe(
            // timeout(4000),
            // catchError(this.handleError)
        );
        const awaitRequest = await chiamata$.toPromise();

        return await awaitRequest;
        //        return await lastValueFrom();

    }

    patchRequestLara<T>(urlServer: string, urlFunction: string, parametri: any): Observable<any> {
        return this.http.patch(urlServer + urlFunction, parametri)
            .pipe(
                timeout(10000),
                // catchError(this.handleError)
            );
    }

    async postRequestLaraAsync(urlServer: string, urlFunction: string, parametri: any): Promise<any> {
        return await this.http.post(urlServer + urlFunction, parametri)
            .pipe(
                timeout(10000),
                // catchError(this.handleError)
            ).toPromise();
    }

    deleteRequestLara(urlServer: string, urlFunction: string, parametri: any): Observable<any> {
        return this.http.delete(urlServer + urlFunction,
            {
                params: parametri
            })
            .pipe(
                timeout(10000),
                // catchError(this.handleError)
            );
    }

    postRequest<T>(urlServer: string, urlFunction: string, parametri: any): Observable<any> {
        return this.http.post(urlServer + urlFunction, parametri
        )
            .pipe(
                // se entro un minuto non risponde da errore
                // timeout(60000),
                // catchError(this.handleError)
            );
    }
    putRequest(urlServer: string, urlFunction: string, parametri: any): Observable<any> {
      return this.http.put(urlServer + urlFunction, parametri
      )
          .pipe(
              // se entro un minuto non risponde da errore
              // timeout(60000),
              // catchError(this.handleError)
          );
  }

    async postRequestAsync(urlServer: string, urlFunction: string, body: any): Promise<any> {
        return await this.http.post(urlServer + urlFunction, body)
            .pipe(
                timeout(60000),
                // catchError(this.handleError)
            );
    }



    /**
     * Questa funzione viene utilizzata per eseguire un comando dal server una volta chiuso il modal
     * è dentro afterClosed().subscribe
     * @param risposta è un oggetto con: avanti, button_who, valori
     */
    public eventoImpostoServer(risposta: any): void {
        if (risposta.avanti) {
            if (risposta.button_who === 'close') {
                if (risposta.valori.array_path_close.length > 0) {
                    if (risposta.valori.queryParams_close) {
                        this.router.navigate(risposta.valori.array_path_close, { queryParams: risposta.valori.queryParams_close });
                        // this.router.navigate(['/anagrafica/socio', this.tipo_richiesta, 'aggiungi'],
                        // {queryParams: {anno: this.anno_richiesta, rifidanno: this.rifidanno_richiesta}});
                    } else {
                        this.router.navigate(risposta.valori.array_path_close);
                    }
                }
            } else if (risposta.button_who === 'go') {
                if (risposta.valori.array_path_go.length > 0) {
                    if (risposta.valori.queryParams_go) {
                        this.router.navigate(risposta.valori.array_path_go, { queryParams: risposta.valori.queryParams_go });
                    } else {
                        this.router.navigate(risposta.valori.array_path_go);
                    }
                }
            }
        }
    }




     /**
   * Ricarica il componente
   */
       reloadCurrentRoute() {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
          console.log(currentUrl);
        });
      }

    /*
     * Sostituito con interceptor http-error
     */
    // private handleError(error: HttpErrorResponse): Observable<any> {
    //     if (error.error instanceof ErrorEvent) {
    //         // A client-side or network error occurred. Handle it accordingly.
    //         console.error('An error occurred:', error.error.message);
    //     } else {

    //         console.error(
    //             `Backend returned code ${error.status}, ` +
    //             `body was: ${error.error}, ` +
    //             `errore completo: ${error}`);
    //         console.error(error);
    //     }
    //     // Return an observable with a user-facing error message.
    //     return throwError(
    //         'Si è verificato un errore; riprova più tardi. ' +
    //         'Se il problema persiste, contatta l\'assistenza. ');
    // }
}

import { createAction, props } from '@ngrx/store';

export const setTitle = createAction(
  '[Page] Set Title',
  props<{ title: string }>()
);

export const setReadonly = createAction(
  '[Page] Set Readonly',
  props<{ readonly: boolean }>()
);

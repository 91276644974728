import { PopupInfoVal } from "../interfaces/popup-info-val";
import { PopupResponseCallApi } from "../interfaces/popup-response-call-api";

export class ObjPopupResponseCallApi implements PopupResponseCallApi{
  // 'error' | 'warning' | 'success'
  tipo?: string = 'warning';
  titolo: string = 'titolo';
  messaggio: string = '';
  // i dati che hai bisogno
  valore?: PopupInfoVal;
  // la pagina a cui vuoi andare
  pagina: string = '';
  // valore passato quando si chiude il popup con il tasto chiudi: true o false (vai a una pagina o non andare)
  dialogResultChiudi: boolean = false;
  dialogResultGo: boolean = true;
  // viene usato se 200 e voglio specificare quale popup usare
  tipoPopup?: string;
  // se true, per chiudere il popup devi cliccare sui pulsanti. Se false il popup si chiude anche cliccando fuori
  disableClose?: boolean;
}

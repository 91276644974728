import { Injectable } from '@angular/core';
import { IconsComponent } from '../components/icons/icons.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // icona home
  // private _fontset_iconhome: ElementRef;
  child_home_icon = new Subject<IconsComponent>();
  /*
  // Observer di rxjs
  child_calendar = new Subject<ElementRef>();
  child_cliente = new Subject<ElementRef>();
  child_home = new Subject<ElementRef>();
  // div menu
  private _id_menu: ElementRef;

*/

  // // titolo pagina
  // titolo_pagina = new Subject<string>();
  private _fontset_iconhome!: IconsComponent;

  // // pagina in sola lettura
  // private pageReadonlyValueSubject = new BehaviorSubject<boolean>(false);
  // pageReadonlyValue$ = this.pageReadonlyValueSubject.asObservable();

  // setPageReadonlyValue(value: any) {
  //   this.pageReadonlyValueSubject.next(value);
  // }

  constructor() {
  }

  /**
   * Registro l'observer, in ogni costruttore c'è l'Observable che con subscribe lo risolve
   * @param mittente, il componente che lo chiama in ngAfterViewChecked()
   */
  sendDivMenu(mittente: string): void {
    if (mittente === 'p-calendar') {
      //  this.child_calendar.next(this._id_menu);
    } else if (mittente === 'p-cliente') {
      // this.child_cliente.next(this._id_menu);
    } else if (mittente === 'p-home') {
      // menu
      // this.child_home.next(this._id_menu);
      // icona home
      this.child_home_icon.next(this._fontset_iconhome);
    }
  }

  /*
  set renderer_menu(id_menu: ElementRef) {
      this._id_menu = id_menu;
  }*/

  /*
  La funziona invia un evento con il nuovo titolo
  */
  // changeTitle(titolo: string): void {
  //   this.titolo_pagina.next(titolo);
  // }

  set renderer_fontset_iconhome(fontset_iconhome: IconsComponent) {
    this._fontset_iconhome = fontset_iconhome;
  }

  /**
 * La funzione sostituisce str_search con str_replace nell'array array_values se l'etichetta è nell'array array_campi
 * (anche una parte dell'etichetta)
 * @param array_values
 * @param array_campi
 * @param str_search
 * @param str_replace
 * @returns
 */
  findAndReplace(array_values: any[], array_campi: any[], str_search: string, str_replace: string) {
    for (var key in array_values) {
      //console.log(key);
      array_campi.forEach(
        (campo) => {
          if (key.includes(campo) && array_values[key]) {
            // console.log('dentro');
            array_values[key] = String(array_values[key]).replace(str_search, str_replace);
            return;
          }

        }
      )

    }
    return array_values;
  }
  /**
    * La funzione sostituisce str_search con str_replace nell'array array_values se l'etichetta è nell'array array_campi
    * (etichetta esattamente quella)
    * @param array_values
    * @param array_campi
    * @param str_search
    * @param str_replace
    * @returns
    */
  // findAndReplaceStrict
  // (array_values: {[keyobj: string]: any}, array_campi: any[], str_search: string, str_replace: string) {
  //   for (var key in array_values) {
  //     // console.log(key);
  //     if (array_campi.includes(key) && array_values[key]) {
  //       array_values[key] = String(array_values[key]).replace(str_search, str_replace);
  //     }
  //   }
  //   return array_values;
  // }

  findAndReplaceStrict<T extends object>(
    obj: T,
    array_campi: string[],
    str_search: string,
    str_replace: string
  ): T {
    for (const key of Object.keys(obj)) {
      // Controlla se la chiave è presente nell'array di campi
      if (array_campi.includes(key) && obj[key as keyof T]) {
        // Converti il valore in stringa
        const value = String(obj[key as keyof T] ); // Aggiungi "as string"
        // Esegui la sostituzione
        obj[key as keyof T] = value.replace(str_search, str_replace) as T[keyof T];;
      }
    }
    return obj;
  }

  /**
   * Stessa cosa della funzione precedente, ma prende in ingresso un array di obj
   * [{},{}, ...]
   * @param arraydiobj
   * @param array_campi
   * @param str_search
   * @param str_replace
   * @returns
   */
  findAndReplaceStrictArrayObj(arraydiobj: any[], array_campi: any[], str_search: string, str_replace: string) {
    arraydiobj.map(
      (row) => {
        return this.findAndReplaceStrict(row, array_campi, str_search, str_replace);
      }
    );
    return arraydiobj;
  }
  findAndReplaceArrayObj(arraydiobj: any[], array_campi: any[], str_search: string, str_replace: string) {
    arraydiobj.map(
      (row) => {
        return this.findAndReplace(row, array_campi, str_search, str_replace);
      }
    );
    return arraydiobj;
  }

  datePipe = new DatePipe('it');
  /**
   *
   * @param MatDatePicker
   * @returns  string di tipo yyyy-MM-dd
   */
  fromDateToString(obj_date: any): string {
    if (typeof obj_date === 'object') {
      const data_string = this.datePipe.transform(obj_date, 'yyyy-MM-dd');
      if (data_string == null) {
        return '';
      } else {
        return data_string;
      }
    } else if (obj_date === undefined) {
      return '';
    } else {
      return obj_date;
    }

  }
  /**
   *
   * @param date string con formato yyyy-MM-dd
   * @returns Date
   */
  stringToDate(date: string): Date | null {
    if (date && date.includes('-')) {
      const array_date = date.split('-');
      return new Date(Number(array_date[0]), (Number(array_date[1]) - 1), Number(array_date[2]));
    } else {
      return null;
    }

  }

  /**
   * Trasforma hex in rgb
   * @param hex --> string di tipo #000000
   * @returns
   */
  hexToRgb(hex: string) {
    /*const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });*/
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;

  }

  /**
* La funzione trasforma un dataurl (image/png as base64) in un file
* @param dataurl
* @param filename
* @returns
*/
  public dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /**
       * Funzione restituisce l'url che si vede nella barra di ricerca es: /anagrafica/cfp/cliente/1
       * @param route
       * @returns
       */
  public getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }
  /**
  * Funzione restituisce l'url come passato in app-routing.module.ts es: /anagrafica/cfp/cliente/:id
  * @param route
  * @returns
  */
  public getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    // console.log(route.params);
    return '/' + route.pathFromRoot
      .filter((v) => {
        return v.routeConfig;
      })
      .map((v) => {
        return v.routeConfig!.path;
      })
      .join('/');
  }

}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../../services/authentication.service';
import { MatButtonModule } from '@angular/material/button';


interface DialogDataContenuto {
  descrizione: string;
}

export interface DialogData {
  tipo: 'error' | 'warning' | 'success';
  titolo: string;
  error_code: string;
  error_text: string;
  contenuto: DialogDataContenuto;
  // array con dentro oggetto
  dati: any;
  pagina: string;
  dialogResultChiudi: boolean;
  dialogResultGo: boolean;
  disableClose: boolean;
}
@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [MatDialogModule, CommonModule, MatButtonModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
  classButton = '';
  pagina = '';
  codice_errore: string | null = null;
  testo_errore: any = null;
  dati_risposta: any = null;
  url = null;
  dialogResultChiudi = false;
  testoButtonGo = 'Vai';
  visualizzaButtonGo = false;
  dialogResultGo = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
      public dialogRef: MatDialogRef<ModalComponent>, private authenticationService: AuthenticationService,
      private router: Router) {
      if (data.tipo === 'error') {
          this.classButton = 'btn btn-danger';
          this.dialogRef.disableClose = true;
      } else if (data.tipo === 'success') {
          this.classButton = 'btn btn-primary';
      } else {
          this.classButton = 'btn btn-warning';
      }
      // console.log('-----');
      // console.log(data);
      if(data.disableClose && data.disableClose){
          // console.log('àààà');
          // console.log(data.disableClose);
          this.dialogRef.disableClose = data.disableClose;
      }
      this.codice_errore = data.error_code;
      this.testo_errore = data.error_text;
      this.dati_risposta = data.dati;
      if (this.dati_risposta && this.dati_risposta.pulsanteGo) {
          this.visualizzaButtonGo = this.dati_risposta.pulsanteGo;
          if (this.dati_risposta.testoPulsanteGo) {
              this.testoButtonGo = this.dati_risposta.testoPulsanteGo;
          }
      }
      this.pagina = data.pagina;
      this.dialogResultChiudi = data.dialogResultChiudi;
      this.dialogResultGo = data.dialogResultGo;
  }

  ngOnInit(): void {
  }

  private azioneLogOut(): void {
    this.authenticationService.logoutServer();
      // localStorage.removeItem('token-l');
      // this.router.navigate(['/']);
      // this.authService.logOut();
  }

  closeModalGo(): void {
      // console.log(this.visualizzaButtonGo + ' ' + this.dati_risposta.url);
      /*const url = this.dati_risposta.url;
      this.router.navigate([url, this.dati_risposta.id]);*/
      this.dialogRef.close({ avanti: this.dialogResultGo, button_who: 'go', valori: this.dati_risposta });
  }

  closeModal(): void {
      // se non esiste
      if (!this.dialogResultChiudi) {
          if (this.codice_errore == '401' || this.codice_errore == '419') {
              this.azioneLogOut();
          }
          // else if ((this.codice_errore == '403' && this.testo_errore == 'Unauthorized') || this.codice_errore == '0') {
          //     this.azioneLogOut();
          // } else if (this.codice_errore == '500' &&
          //     (this.testo_errore.message == 'The token has been blacklisted' || this.testo_errore.message == 'Token has expired and can no longer be refreshed')) {
          //     this.azioneLogOut();
          // } else if (this.codice_errore == '429') {
          //     this.azioneLogOut();
          // }
          this.dialogRef.close({ avanti: false, button_who: 'close', valori: this.dati_risposta });
      } else {
          // questo valore passato a close serve per fare qualcosa dopo la chiusura
          this.dialogRef.close({ avanti: this.dialogResultChiudi, button_who: 'close', valori: this.dati_risposta });
      }
  }
}


export const environment = {
  production: true,
  urlserver: {
    laravel: 'https://wecodev.wallnet.it/',
    laravelFile: 'https://wecodev.wallnet.it/file/',
    laravelMedia: 'https://wecodev.wallnet.it/media/',
    laravelApi: 'https://wecodev.wallnet.it/'
  }
};

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupResponseCallApi } from '../interfaces/popup-response-call-api';
import { ModalNewupComponent } from '../popup/modal-newup/modal-newup.component';
import { ModalComponent } from '../popup/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class PopupDialogService {

  constructor(private dialog: MatDialog) { }

   /**
     * Popup da chiamare per i successi, per gli errori e per gli warning uso la funzione nell interceptor
     * @param esito oggetto passato da PHP
     */
   popUpDialogSuccess(esito: any, tipo_pass?: any): MatDialogRef<ModalComponent> {
    let tipo = 'success';
    if (tipo_pass) {
        tipo = tipo_pass;
    }
    const titolo = esito.titolo;
    const messaggio = esito.messaggio;
    const pagina = esito.pagina;
    const valori = esito.valore;
    const dialogRef = this.dialog.open(ModalComponent, {
        panelClass: 'modal_popup',
        data: {
            tipo: tipo.toString(),
            titolo: titolo.toString(),
            contenuto: {
                descrizione: messaggio
            },
            pagina: pagina.toString(),
            dialogResultChiudi: esito.dialogResultChiudi,
            dialogResultGo: esito.dialogResultGo,
            disableClose: esito.disableClose,
            dati: valori
        }
    });
    return dialogRef;
    // this.dialogRefPopSucc = dialogRef;
}

  /**
     * Popup da chiamare al posto di confirm().
     * @param esito : PopupResponseCallApi
     * @param tipo_pass : success, warning, error
     * @returns
     */
  popUpDialogConfirm(esito: PopupResponseCallApi, tipo_pass?: any): MatDialogRef<ModalComponent> {
    let tipo = 'success';
    if (tipo_pass) {
        tipo = tipo_pass;
    }
    const titolo = esito.titolo;
    const messaggio = esito.messaggio;
    const pagina = esito.pagina;
    const valori = esito.valore;
    const dialogRef = this.dialog.open(ModalComponent, {
        panelClass: 'modal_popup',
        data: {
            tipo: tipo.toString(),
            titolo: titolo.toString(),
            contenuto: {
                descrizione: messaggio
            },
            pagina: pagina.toString(),
            dialogResultChiudi: esito.dialogResultChiudi,
            dialogResultGo: esito.dialogResultGo,
            dati: valori
        }
    });
    return dialogRef;
}

popUpDialogNewUp(val: PopupResponseCallApi): MatDialogRef<ModalNewupComponent> {

    if (val.tipo?.length == 0) {
        val.tipo = 'success';
    }

    const dialogRef = this.dialog.open(ModalNewupComponent, {
        panelClass: 'modal_popup',
        data: val
    });
    return dialogRef;
}
}

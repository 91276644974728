import { createAction, props } from '@ngrx/store';
import { User } from '../../interfaces/user';
import { PermissionsUser } from '../../interfaces/permissions-user';
import { Company } from '../../interfaces/company';


export const loadUserInfo = createAction('[User] Load User Info');
export const loadUserInfoSuccess = createAction('[User] Load User Info Success', props<{ userInfo: User }>());
export const loadUserInfoFailure = createAction('[User] Load User Info Failure', props<{ error: any }>());

export const loadUserPermissions = createAction('[User] Load User Permissions');
export const loadUserPermissionsSuccess = createAction('[User] Load User Permissions Success',
  props<{ permissions: PermissionsUser }>());
export const loadUserPermissionsFailure = createAction('[User] Load User Permissions Failure', props<{ error: any }>());

export const loadUserCompanyInfo = createAction('[User] Load Company Info');
export const loadUserCompanyInfoSuccess = createAction('[User] Load Company Info Success',
  props<{ companyInfo: Company }>());
export const loadUserCompanyInfoFailure = createAction('[User] Load Company Info Failure', props<{ error: any }>());

export const logout = createAction('[User] Logout');
